import "core-js/stable";
import "regenerator-runtime/runtime";

// basics
const $ = require("jquery");

window.jQuery = $;
window.$ = $;
global.$ = $;

/* global __DEV__ */
/* global window */
// eslint-disable-next-line import/named
const locale = require("./Locale");

// Vendors
// conditional import, will not require both modules
// eslint-disable-next-line import/no-dynamic-require
require(__DEV__ ? "jquery-migrate" : "jquery-migrate/dist/jquery-migrate.min.js");
// Mute all deprecation messages
window.jQuery.migrateMute = true;
// libraries exposing to global objects
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
require("imports-loader?this=>window!Placeholders.js/lib/placeholders.js"); // populates global namespace
// populates global namespace
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
require("imports-loader?jQuery=jquery&this=>window!Placeholders.js/lib/adapters/placeholders.jquery.js");
// polyfill for browsers with media queries support but no matchMedia support (like IE9, iOS Safari 4.1-4.3);
require("match-media");
require("jquery-validation");
require("./bootstrap-tooltip");
require("./jquery.floating"); // custom library
require("typeahead.js/dist/typeahead.jquery.js");
require("@fancyapps/fancybox/src/js/core");
require("@fancyapps/fancybox/src/js/guestures");
require("@fancyapps/fancybox/src/js/thumbs");
require("./jquery.visible");
require("./jquery.scrollLock.jsx");

// eslint-disable-next-line import/no-dynamic-require
require(`jquery-validation/dist/localization/messages_${locale.lang}`);
require("./jquery.validation.methods.jsx").default($);
require("./jquery.mobile.custom");
require("./jquery.serializeObject"); // probably custom library? didn't found same implementation

// eslint-disable-next-line import/no-dynamic-require
require("./bootstrap-scrollspy");
require("./bootstrap-tab");
require("../../../dry/browser");
require("../../../dry/date");
require("../../../dry/dom");
require("../../../dry/fn");
require("../../../dry/net");
require("../../../dry/num");
require("../../../dry/str");

// custom libraries
require("./bootstrap-dropdown"); // customized release

// other
require("Base64");
require("crypto-js/aes");
require("crypto-js/md5");
require("crypto-js/enc-utf8");
require("moment");

// eslint-disable-next-line import/no-dynamic-require
require(`moment/locale/${locale.lang}`);
require("sortablejs");
require("clipboard");
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
require("file-loader?name=def/js/lib/aam_skrz.js&context=client-src/Skrz/Bundle/FrontendBundle!./aam_skrz.js");
require("./bootstrap-toggle");
