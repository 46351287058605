/* global window */

const scrollLockPlugin = ($) => {
	const $html = $("html");
	const $window = $(window);
	let scrollV;
	let scrollH;

	// eslint-disable-next-line no-param-reassign
	$.scrollLock = function scrollLock(lock) {
		if (lock) {
			$html.addClass("scroll-margin");

			scrollV = $window.scrollTop();
			scrollH = $window.scrollLeft();

			$html.addClass("scroll-lock");

			$window.scrollTop(scrollV).scrollLeft(scrollH);
		} else if ($html.hasClass("scroll-lock")) {
			$(".scroll-margin").removeClass("scroll-margin");

			scrollV = $window.scrollTop();
			scrollH = $window.scrollLeft();

			$html.removeClass("scroll-lock");

			$window.scrollTop(scrollV).scrollLeft(scrollH);
		}
	};
};

scrollLockPlugin(window.jQuery);
