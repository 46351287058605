(function($) {

    _isNumeric = function(val) {
        return parseFloat(val) == val;
    };

    _getConvertedVal = function(val) {
        if (_isNumeric(val)) {
            return +val;
        }
        return val;
    };

    _serializeObject = function() {
        var o = {};
        var a = this.serializeArray();

        $.each(a, function() {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(_getConvertedVal(this.value || ''));
            } else {
                o[this.name] = _getConvertedVal(this.value || '');
                /*
                 if (/\[\]$/.test(this.name)) {
                 o[this.name.replace(/(\[\])$/,'')] = [this.value || ''];
                 } else {
                 o[this.name] = this.value || '';
                 }
                 */
            }
        });

        return o;
    };

    $.fn.serializeObject = function() {
        return _serializeObject.apply(this,arguments);
    };

})(jQuery);
