/* eslint-disable */
/* global __DEV__ */
/**
 * jQuery plugin pro fixaci pozice elementu pri skrolovani.
 * @author Jiri Pokorny (mail@jiripokorny.cz)
 */

(function($) {
	var
		domWatcherT = null,
		domWatcherInterval = 1000,
		instances = {},
		instLastId = -1,
		$window = $(window);

	$.floating = function() {};

	// vychozi nastaveni (globalni nastaveni pro vsechny instance)
	$.floating.defaults = {
        limit: 0,
		marginBottom: null,
		marginTop: 0,
		onChange: function() {},
        zIndex: 1000
	};

	$.floating.debug = false;

	function __debug(variable) {
		if ($.floating.debug === true && window.console) {
			__DEV__ && console.log(variable);
		}
	}

	/**
	 * Vytvori nahradni element na miste originalu.
	 */
	function _createSpacer(instance) {
		var dimensions, $spacer;

		if (instance.$spacer) {
			return;
		}

		dimensions = _getDimensions(instance.$el);

		$spacer = $('<div></div>')
			.height(dimensions.height)
			.width(dimensions.width)
			.css({
				padding: dimensions.padding,
				margin: dimensions.margin
			});

		instance.$el.after($spacer);
		instance.$spacer = $spacer;

		return instance;
	}

	/**
	 * Pripoji k elementu plugin.
	 */
	function _elementInit(opts) {
		return this.each(function() {
			var instance, instanceId,
				$el = $(this);

			// nepripojit, pokud casovac jiz existuje
			if (typeof $el.data().floatingId == 'number') {
				return;
			}

			// nastaveni
			opts = $.extend(true,{},$.floating.defaults,opts);

			// identifikace uzlu
			instanceId = ++instLastId;
			$el.data().floatingId = instanceId;

			// objekt instance
			instance = {
				data: {},
				opts: opts,
				$el: $el,
				position: $el.css('position'),
				placement: null
			};

			// inicializace instance
			_fetchOrigCoords(instance,instance.$el);
			_fetchOrigDimensions(instance,instance.$el);
			_createSpacer(instance);
			_setAbsolute(instance);

			$el.on('resize.floating',function(e) {
				_checkResize(instance);
			});

			// zaregistrovat do globalniho seznamu instanci, jakmile je instance plne inicializovana
			instances[instanceId] = instance;
		});
	}

	/**
	 * Ulozi pozici originalu posouvaneho elementu.
	 * @param {jQuery} $node Originalni element jako jQuery objekt
	 */
	function _fetchOrigCoords(instance,$node) {
		var coords;

		$node = $node || instance.$el;
		coords = _getCoords($node);

		instance.data.origOffset = coords.offset;
		instance.data.origPosition = coords.position;

		return instance;
	}

	/**
	 * Ulozi rozmery originalu posouvaneho elementu.
	 * @param {jQuery} $node Originalni element jako jQuery objekt
	 */
	function _fetchOrigDimensions(instance,$node) {
		var dimensions;

		$node = $node || instance.$el;
		dimensions = _getDimensions($node);

		instance.data.origDimensions = dimensions;

		return instance;
	}

	/**
	 * Zjisti aktualni souradnice (offset a position) daneho elementu.
	 */
	function _getCoords($node) {
		return {
			offset: $node.offset(),
			position: $node.position()
		};
	}

	/**
	 * Vrati aktualni rozmery (sirka, vyska, padding a margin) daneho elementu.
	 */
	function _getDimensions($node) {
		var dimensions;

		// @TODO: pocitat pozici pomoci offsetu. Na marginy neni spolehnuti v IE8.
		// http://stackoverflow.com/questions/13095368/jquery-cssmargin-left-gives-auto-back-in-ie7-and-ie8
		dimensions = {
			height: $node.height(),
			width: $node.width(),
			paddingBottom: $node.css('padding-bottom'),
			paddingLeft: $node.css('padding-left'),
			paddingRight: $node.css('padding-right'),
			paddingTop: $node.css('padding-top'),
			marginBottom: $node.css('margin-bottom'),
			marginLeft: $node.css('margin-left'),
			marginRight: $node.css('margin-right'),
			marginTop: $node.css('margin-top')
		};
		dimensions.padding = dimensions.paddingTop + ' ' + dimensions.paddingRight + ' ' + dimensions.paddingBottom + ' ' + dimensions.paddingLeft;
		dimensions.margin = dimensions.marginTop + ' ' + dimensions.marginRight + ' ' + dimensions.marginBottom + ' ' + dimensions.marginLeft;

		return dimensions;
	}

	/**
	 * Vrati pozici konecneho dojezdu
	 */
	function _getLimit(instance) {
		var limit;

		if (typeof instance.opts.limit == 'function') {
			limit = instance.opts.limit();
		} else {
			limit = instance.opts.limit;
		}

		return limit > 0 ? limit : 0;
	}

	/**
	 * Vrati velikost okraje mezi floating elementem a dolni hranou viewportu
	 */
	function _getMarginBottom(instance) {
		var margin;

		if (typeof instance.opts.marginBottom == 'function') {
			margin = instance.opts.marginBottom();
		} else {
			margin = instance.opts.marginBottom;
		}

		return margin;
	}

    /**
     * Vrati velikost okraje mezi floating elementem a horni hranou viewportu
     */
    function _getMarginTop(instance) {
        var margin;

        if (typeof instance.opts.marginTop == 'function') {
            margin = instance.opts.marginTop();
        } else {
            margin = instance.opts.marginTop;
        }

        return margin;
    }

	function _throttle(func, wait, options) {
		var context, args, result;
		var timeout = null;
		var previous = 0;
		options || (options = {});
		var later = function() {
			previous = options.leading === false ? 0 : (new Date()).getTime();
			timeout = null;
			result = func.apply(context, args);
			context = args = null;
		};
		return function() {
			var now = (new Date()).getTime();
			if (!previous && options.leading === false) previous = now;
			var remaining = wait - (now - previous);
			context = this;
			args = arguments;
			if (remaining <= 0) {
				clearTimeout(timeout);
				timeout = null;
				previous = now;
				result = func.apply(context, args);
				context = args = null;
			} else if (!timeout && options.trailing !== false) {
				timeout = setTimeout(later, remaining);
			}
			return result;
		};
	}

	/**
	 * Aktualizuje pozici prvku po skrolovani v okne.
	 */
	function _checkScroll(instance) {
		var limit, marginBottom, marginTop;

		limit = _getLimit(instance);
		marginBottom = _getMarginBottom(instance);
        marginTop = _getMarginTop(instance);

        // Sticky pozice vuci dolni hrane viewportu
        if (marginBottom != null) {
	        // pocatecni (dolni) dojezd => absolutni pozice
	        if ($window.scrollTop() + $window.height() - Math.max(marginBottom,parseInt(instance.data.origDimensions.marginBottom)) >= instance.data.origOffset.top + instance.$el.outerHeight(true)) {
		        _setAbsolute(instance);
	        // konecny (horni) dojezd => absolutni pozice
	        } else if ($window.scrollTop() + $window.height() - Math.max(marginBottom,parseInt(instance.data.origDimensions.marginBottom)) - instance.$el.outerHeight(true) <= limit) {
		        _setAbsolute(instance, 'end');
	        } else {
		        _setFixed(instance);
	        }
        // Sticky pozice vuci horni hrane viewportu
        } else {
			// pocatecni (horni) dojezd => absolutni pozice
			if ($window.scrollTop() + Math.max(marginTop,parseInt(instance.data.origDimensions.marginTop)) <= instance.data.origOffset.top) {
				_setAbsolute(instance);
			// konecny (dolni) dojezd => absolutni pozice
			} else if ($window.scrollTop() + Math.max(marginTop,parseInt(instance.data.origDimensions.marginTop)) + instance.$el.outerHeight(true) >= limit) {
				_setAbsolute(instance, 'end');
			// pozice mezi obema dojezdy => fixni pozice
			} else {
				_setFixed(instance);
			}
        }
	}

	/**
	 * Aktualizuje rozmery prvku po zmene velikosti jeho originalu.
	 */
	function _checkResize(instance) {
		_fetchOrigDimensions(instance,instance.$el);
		_setDimensions(instance.$spacer,instance.data.origDimensions);
		_checkScroll(instance);
	}

	/**
	 * Napozicuje element absolutne tak, aby byl umisten na originalnim miste. V pripade placement=end jej umisti na pozici dolniho dojezdu.
	 * @param {Object} instance
	 * @param {string} placement
	 */
	function _setAbsolute(instance,placement) {
		var limit, top, topOffset;

		placement = placement || 'begin';

		instance.$el.css({
			height: instance.data.origDimensions.height + 'px',
			left: instance.data.origPosition.left,
			marginTop: instance.data.origDimensions.marginTop,
			position: 'absolute',
			width: instance.data.origDimensions.width + 'px',
			zIndex: instance.opts.zIndex
		});

		if (placement == 'end') {
			limit = _getLimit(instance);
			// Sticky pozice vuci dolni hrane viewportu
			if (instance.opts.marginBottom != null) {
				topOffset = limit;
			// Sticky pozice vuci horni hrane viewportu
			} else {
				topOffset = limit - instance.$el.outerHeight(true);
			}
			top = topOffset - (instance.$el.offset().top - instance.$el.position().top);

			instance.$el.css({
				top: top
			});
		} else {
			instance.$el.css({
				top: instance.data.origPosition.top
			});
		}

		if (instance.position != 'absolute' && instance.opts.onChange) {
			instance.opts.onChange(instance.$el, 'absolute');
		}

		instance.position = 'absolute';
		instance.placement = placement;
	}

	/**
	 * Nastavi element do fixni pozice.
	 */
	function _setFixed(instance) {
        var marginBottom, marginTop, style;

        marginBottom = _getMarginBottom(instance);
        marginTop = _getMarginTop(instance);

		style = {
			height: instance.data.origDimensions.height + 'px',
			left: instance.data.origOffset.left,
			position: 'fixed',
			width: instance.data.origDimensions.width + 'px',
			zIndex: instance.opts.zIndex
		};
		// Sticky pozice vuci dolni hrane viewportu
		if (marginBottom != null) {
			style.bottom = Math.max(marginBottom, parseInt(instance.data.origDimensions.marginBottom));
			style.marginBottom = 0;
			style.top = "auto";
		// Sticky pozice vuci horni hrane viewportu
		} else {
			style.top = Math.max(marginTop, parseInt(instance.data.origDimensions.marginTop));
			style.marginTop = 0;
			style.bottom = "auto";
		}
		instance.$el.css(style);

		if (instance.position != 'fixed' && instance.opts.onChange) {
			instance.opts.onChange(instance.$el, 'fixed');
		}

		instance.position = 'fixed';
		instance.placement = null;
	}

	/**
	 * Nastavi rozmery (sirka, vyska, padding a margin) daneho elementu.
	 */
	function _setDimensions($node,dimensions) {
		$node
			.height(dimensions.height)
			.width(dimensions.width)
			.css({
				padding: dimensions.padding,
				margin: dimensions.margin
			});

		return $node;
	};

	var glob = {
		/**
		 * Spusti sledovac detekujici zmeny pozice originalniho elementu v DOMu.
		 */
		_domWatcherStart: function() {
			if (domWatcherT) {
				return;
			}
			domWatcherT = setInterval(
				glob._checkDom,
				domWatcherInterval
			);
		},
		/**
		 * Zastavi sledovac detekujici zmeny pozice originalniho elementu v DOMu.
		 */
		_domWatcherStop: function() {
			if (!domWatcherT) {
				return;
			}
			clearInterval(domWatcherT);
			domWatcherT = null;
		},
		/**
		 * Detekuje zmeny (pozice originalniho elementu) v DOMu.
		 */
		_checkDom: function() {
			var i;

			for (i in instances) {
				if (instances[i].$el.is(':visible')) {
					_fetchOrigCoords(instances[i],instances[i].$spacer);
					_checkScroll(instances[i]);
				}
			}
		},
		/**
		 * Window.resize handler dohlizejici nad vsemi instancemi pluginu.
		 */
		_windowResizeEvent: function (e) {
			var i;

			for (i in instances) {
				if (instances[i].$el.is(':visible')) {
					_fetchOrigCoords(instances[i],instances[i].$spacer);
					_checkScroll(instances[i]);
				}
			}
		},
		/**
		 * Window.scroll handler dohlizejici nad vsemi instancemi pluginu.
		 */
		_windowScrollEvent: function(e) {
			var i;

			for (i in instances) {
				_checkScroll(instances[i]);
			}
		}
	};

    // TODO: destroy metoda
	var methods = {};

	$.fn.floating = function(method) {
		if (methods[method]) {
	    	return methods[method].apply(this,Array.prototype.slice.call(arguments,1));
	    } else if (typeof method === 'object' || ! method) {
	    	return _elementInit.apply(this,arguments);
	    } else {
	    	$.error('Method ' +  method + ' does not exist on jQuery.floating');
	    }
	};

	// pri inicializaci knihovny
	$(window).on('resize.floating', glob._windowResizeEvent);
	$(window).on('scroll.floating', _throttle(glob._windowScrollEvent, 100));
	glob._domWatcherStart();

})(jQuery);
